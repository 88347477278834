@font-face {
  font-family: "Quickzag";
  src: local("Quickzag"),
    url("./fonts/Quickzag/Quickzag.ttf") format("trueType");
}

@font-face {
  font-family: "re-do";
  src: local("re-do"), url("./fonts/re-do/re-do.ttf") format("trueType");
}

/* As SVG does not provide a default visual style for links,
   it's considered best practice to add some */

@namespace svg url(http://www.w3.org/2000/svg);
/* Necessary to select only SVG <a> elements, and not also HTML’s.
      See warning below */

svg|a:link,
svg|a:visited {
  cursor: pointer;
}

svg|a text,
text svg|a {
  fill: blue; /* Even for text, SVG uses fill over color */
  text-decoration: underline;
}

svg|a:hover,
svg|a:active {
  outline: dotted 1px blue;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.iconWrapper {
  display: flex;
  height: 2rem;
  width: 2rem;
}

.icon {
  width: 2rem;
  height: 2rem;
  top: 2rem;
  position: relative;
}

.icoBlueShift {
  fill: rgba(0, 225, 255, 0.5);
  z-index: 4;
}

.icoPinkShift {
  fill: rgba(255, 0, 255, 0.4);
  z-index: 4;
}

.icoBlueShift:hover + .icoWhite {
  fill: white;
}

.icoMain {
  fill: rgba(255, 255, 255, 0.8);
  z-index: 5;
}

.icoMain a {
  fill: rgba(255, 225, 255, 1);
  z-index: 5;
}

.skillsContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: fit-content;
  color: white;
  font-family: re-do;
  z-index: 2;
  font-size: 0.6rem;
  bottom: 2rem;
  /* top: 1rem; */
}

.hello {
  position: absolute;
  font-family: monospace;
  color: white;
  z-index: 2;
  /* margin-bottom: 100px; */
  font-size: 2rem;
  left: 3rem;
  top: -0.5rem;
}

.gradient-text {
  font-family: re-do, monospace;
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #f4e, rgba(0, 225, 255, 0.8));
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  z-index: 3;
  width: 100%;
  height: 100%;
}

.writeCode {
  position: relative;
  font-family: re-do, monospace;
  color: white;
  font-size: 1rem;
  right: 1rem;
  top: -0.75rem;
  line-height: 0.5rem;
  text-align: right;
  width: 100%;
}

.nameText {
  font-family: Quickzag, Arial, serif;
  font-weight: black;
  position: relative;
  color: white;
  font-size: 15rem;
  z-index: 2;
}

.nameTextBlu {
  color: rgba(0, 225, 255, 0.8);
  position: relative;
  left: -0.4rem;
  top: -0.35rem;
  z-index: 3;
}

.nameTextRed {
  color: rgba(255, 0, 255, 0.75);
  position: relative;
  left: 0.4rem;
  top: 0.3rem;
}

.terrainCanvas {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
